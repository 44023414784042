import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, FilterDescriptor, process, State } from '@progress/kendo-data-query';
import { CategorySum } from './graph/category-sum';
import { GraphData } from './graph/graph-data';
import { ODataResponse } from './odata-response';
import { PageableOptions } from '../constants/pageable-options';

export abstract class KendoGridBase<Entity>  {

    public gridName = '';
    public kendoGridState: State = {
        skip: 0,
        take: PageableOptions.pageSizes[0],
        filter: {
            logic: 'and',
            filters: []
        }
    };

    dataGridView: GridDataResult;
    graphData = new GraphData();
    loading = true;
    entities: Entity[];
    summaries: CategorySum[] = [];
    entitySearchHint = '';
    public searchBarFilters: CompositeFilterDescriptor = null;

    public generateGridDataViewOData(odataResponse: ODataResponse<Entity[]>) {
        if (!odataResponse || !odataResponse.value) {
            this.loading = false;
            return;
        }
        this.loading = false;
        this.entities = odataResponse.value;
        this.dataGridView = {
            data: this.entities,
            total: odataResponse.count
        };
    }

    public generateGridDataView() {
        this.dataGridView = process(this.entities, this.kendoGridState);
        this.loading = false;
    }

    public dataStateChange = (state: DataStateChangeEvent) => {
        this.kendoGridState = state;
        this.generateGridDataView();
    };

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    refreshView(): void { }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getQuickSearchFilters(query: string): FilterDescriptor[] { return []; }

    public onAfterValueChanged(query: any): void {
        console.log("onAfterValueChanged");
        // No query entered, clear the filter
        if (query == '') {
            this.searchBarFilters = null;
        } else {
            // Build the filter
            this.searchBarFilters = { filters: [], logic: 'or' };
            const filtersArray = this.getQuickSearchFilters(query);
            if (filtersArray && filtersArray.length > 0) {
                filtersArray.forEach(filter => {
                    this.searchBarFilters.filters.push(filter);
                });
            }
        }
        this.kendoGridState.skip = 0;
        this.refreshView();
    }
}
