@if (this.showMenu) {
<div class="row">
  <div class="col-12 col-md-5 col-xl-6">
    <div class="row mx-0 d-none d-md-block">
      <label class="labelBlack">{{ gridTitle | translate }}</label>
    </div>
  </div>
  <div class="col-12 col-md-7 col-xl-6">
    <div class="row mx-0">
      @for (value of totalValues; track value; let i = $index) {
      <div class="col px-1">
        <ntw-title-value [titleValue]="value"></ntw-title-value>
      </div>
      }
    </div>
  </div>
</div>
}
@if (this.showMenu) {
<div class="row py-2">
  <div class="col-12 col-md-5">
    <kendo-textbox ntwExtended class="searchShared" placeholder="{{ 'shared.search' | translate }}"
      (valueChange)="onValueChange($event)" (afterValueChanged)="onAfterValueChanged($event)">
      <ng-template kendoTextBoxSuffixTemplate>
        <kendo-svg-icon [icon]="this.icons.search"></kendo-svg-icon>
      </ng-template>
    </kendo-textbox>
  </div>
</div>
}
<div class="row">
  <div class="col-12">
    <kendo-grid #grid [data]="dataGridView" [navigable]="true" [resizable]="true" [sortable]="true" [loading]="loading"
      [pageable]="pageableOptions" [pageSize]="kendoGridState.take" [skip]="kendoGridState.skip"
      [sort]="kendoGridState.sort" [filter]="kendoGridState.filter" [rowSelected]="isRowSelected"
      [selectedKeys]="selectedKeys" [selectable]="this.selectableSettings" (dataStateChange)="dataStateChange($event)"
      (filterChange)="filterChange($event)" (cellClick)="onCellClick($event)" [kendoGridSelectBy]="this.idFieldName"
      [columnMenu]="{ filter: true, sort: true, columnChooser: false }">
      @if (this.showCheckBoxColumn && this.selectableSettings.enabled) {
      <kendo-grid-column [width]="40" [class]="{ 'text-center': true }" [resizable]="false" [columnMenu]="false">
        <ng-template kendoGridHeaderTemplate>
          <div class="select-all-checkbox">
            <input type="checkbox" class="k-checkbox k-checkbox-md k-rounded-md" kendoCheckBox
              [disabled]="this.isSelectAllDisabled()" [checked]="this.isAllSelected()"
              [indeterminate]="this.isAnySelected() && !this.isAllSelected()" (click)="this.onAllCheckboxClick()" />
          </div>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <input type="checkbox" class="k-checkbox k-checkbox-md k-rounded-md" kendoCheckBox
            [checked]="this.isEntitySelected(dataItem)" (click)="this.onCheckboxClick(dataItem)" />
        </ng-template>
      </kendo-grid-column>
      }`
      @for (col of columnSettings; track col) {
      <kendo-grid-column [field]="col.field" [title]="col.title | translate" height="50"
        [width]="col.width ? col.width : 50" [minResizableWidth]="col.minResizableWidth ? col.minResizableWidth : 50"
        [sortable]="col.sortable === false ? col.sortable : true"
        [filterable]="col.filterable === false ? col.filterable : true" [filter]="col.filter" [format]="col.format"
        [editable]="col.editor ? true : false" [editor]="col.editor ? col.editor : null">
        <ng-template kendoGridCellTemplate let-dataItem>
          @if (col.loadingField && dataItem[col.loadingField]) {
          <kendo-loader class="me-1" size="small"></kendo-loader>
          }
          @if (col.errorField && dataItem[col.errorField]) {
          @if (col.valueOnError) {
          <span>{{ col.valueOnError | translate }}</span>
          }
          @if (!col.valueOnError) {
          <span>{{ 'shared.unknown' | translate }}</span>
          }
          }
          @if ((!col.loadingField || (col.loadingField && !dataItem[col.loadingField]))
          && (!col.errorField || (col.errorField && !dataItem[col.errorField]))) {
          @if (col.translate) {
          <span>{{dataItem[col.field] | translate }}</span>
          }
          @if (col.filter === 'boolean') {
          @if (dataItem[col.field] === true) {
          <span>{{ 'shared.true' | translate }}</span>
          }
          @if (dataItem[col.field] === false) {
          <span>{{ 'shared.false' | translate }}</span>
          }
          @if (!dataItem[col.field] && dataItem[col.field] !== false) {
          <span>{{ 'shared.unknown' |
            translate }}</span>
          }
          }
          @if (col.format === '{0:n0}') {
          <span>{{dataItem[col.field] | number:'1.0-2':'sv-SE'}}</span>
          }
          @if (col.format === '{0:n2}') {
          <span>{{dataItem[col.field] | number:'1.2-2':'sv-SE'}}</span>
          }
          @if (col.editor && !col.editorSettings.displayValue) {
          }
          @if (!col.translate
          && col.filter !== 'boolean'
          && col.format !== '{0:n0}'
          && col.format !== '{0:n2}'
          && !(col.editor && !col.editorSettings.displayValue)) {
          @if (isDate(dataItem[col.field])) {
          <span>{{dataItem[col.field] | date:col.format ?? 'dd/MM/yyyy'}}</span>
          }
          @if (!isDate(dataItem[col.field])) {
          <span>{{dataItem[col.field]}}</span>
          }
          }
          }
        </ng-template>
        @if (col.editor === 'numeric'; as dataItem) {
        <ng-template kendoGridEditTemplate let-dataItem style="height: 50px;">
          <kendo-numerictextbox [formControl]="getFormControl(dataItem, col.field)"
            [step]="col.editorSettings?.step ?? 1" [format]="col.editorSettings?.format ?? 'n2'"
            [min]="col.editorSettings?.min" [max]="col.editorSettings?.max"
            [decimals]="col.editorSettings?.decimals ?? 2" [autoCorrect]="col.editorSettings?.autoCorrect ?? false"
            [value]="dataItem.value[col.field]">
          </kendo-numerictextbox>
        </ng-template>
        }
        <ng-template *ngIf="!col.filter || col.filter==='text'" kendoGridFilterMenuTemplate let-filter
          let-column="column" let-filterService="filterService">
          <kendo-grid-string-filter-menu [column]="column" [filter]="this.applyFilterCaseInvariance(col, filter)"
            [filterService]="filterService" operator="contains">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
            <kendo-filter-eq-operator></kendo-filter-eq-operator>
            <kendo-filter-neq-operator></kendo-filter-neq-operator>
            <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
            <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            <kendo-filter-isempty-operator></kendo-filter-isempty-operator>
            <kendo-filter-isnotempty-operator></kendo-filter-isnotempty-operator>
          </kendo-grid-string-filter-menu>
        </ng-template>
        <ng-template *ngIf="!col.filter || col.filter==='date'" kendoGridFilterMenuTemplate let-filter
          let-column="column" let-filterService="filterService">
          <kendo-grid-date-filter-menu [column]="column" [filter]="filter" [filterService]="filterService"
            operator="contains">
            <kendo-filter-isnotnull-operator> </kendo-filter-isnotnull-operator>
            <kendo-filter-isnull-operator></kendo-filter-isnull-operator>
            <kendo-filter-after-operator></kendo-filter-after-operator>
            <kendo-filter-before-operator></kendo-filter-before-operator>
          </kendo-grid-date-filter-menu>
        </ng-template>
      </kendo-grid-column>
      }
      @if (this.showButtonsColumn) {
      <kendo-grid-column [width]="this.getButtonsColumnWidth()" [resizable]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="row flex-nowrap">
            <div class="d-none d-xxl-block col"></div>
            @if (this.showOpenButton) {
            <div class="col-auto float-end">
              <button class="command-button k-button-flat" kendoButton look="flat"
                (click)="this.onOpenButtonClicked(dataItem)">
                <kendo-svg-icon [icon]="openButtonIcon(dataItem)" class="button-icon"></kendo-svg-icon>
              </button>
            </div>
            }
            @if (this.showDeleteButton || this.showDeleteButtonFn(dataItem)) {
            <div class="col-auto float-end" [ngClass]="{'ps-0': this.showOpenButton }">
              <button class="command-button k-button-flat" kendoButton look="flat"
                [disabled]="!this.isItemDeleteable(dataItem)" (click)="this.onDeleteButtonClicked(dataItem)">
                <kendo-svg-icon [icon]="this.icons.trash" class="button-icon"></kendo-svg-icon>
              </button>
            </div>
            }
          </div>
        </ng-template>
      </kendo-grid-column>
      }
    </kendo-grid>
  </div>
</div>