<div>
  <ng-template [cdkPortalOutlet]="portalForWindow"></ng-template>
</div>
<kendo-listview [data]="this.displayedItems" [containerClass]="'k-d-flex k-flex-col k-flex-nowrap'">
  <ng-template kendoListViewHeaderTemplate>
    <div class="row py-1 nimbus-sans-font">
      <div class="col-6 col-md title pe-1"> {{this.title | translate}} </div>
      @if (this.isHeaderOptionVisible) {
        <div class="col-6 col-md pick-up-address">
          <div class="ms-auto me-0 d-flex">
            <div class="pick-up-address-label">{{this.headerOptionTitle | translate}}</div>
            <input type="checkbox" kendoCheckBox class="pick-up-address-checkbox k-checkbox k-checkbox-md k-rounded-md"
              [(ngModel)]="this.isHeaderOptionChecked" [disabled]="this.isReadOnly"
              (ngModelChange)="this.OnHeaderOptionCheckedChanged($event)" />
            </div>
          </div>
        }
        <div class="col-12 col-md">
          <kendo-textbox placeholder="{{'shared.filterItems' | translate}}"
            (valueChange)="handleFilterChange($event)">
            <ng-template kendoTextBoxSuffixTemplate>
              <kendo-svg-icon [icon]="this.icons.search"></kendo-svg-icon>
            </ng-template>
          </kendo-textbox>
        </div>
      </div>
    </ng-template>
    <ng-template kendoListViewItemTemplate let-dataItem="dataItem">
      @if (this.type === 'item') {
        <ntw-list-item class="list-item"
          [(isChecked)]="dataItem.isChecked" [OnCheckBoxClicked]="this.OnCheckBoxClicked"
          [isReadOnly]="this.isReadOnly" [dataItem]="dataItem">
        </ntw-list-item>
      }
      @if (this.type === 'warehouse') {
        <ntw-list-item [ItemName]="dataItem.warehouseName" class="list-item"
          [(isChecked)]="dataItem.isChecked" [OnCheckBoxClicked]="this.OnCheckBoxClicked"
          [isReadOnly]="this.isReadOnly" [dataItem]="dataItem">
        </ntw-list-item>
      }
      @if (this.type === 'product-item') {
        <ntw-product-list-item class="list-item" [dataItem]="dataItem" [isDeleted]="this.IsItemDeleted(dataItem)"
          [inventory]="this.getInventoryByProduct(dataItem)" [OnCopyClicked]="this.OnCopyClicked"
          [OnDeleteClicked]="this.OnDeleteClicked" [OnUndoClicked]="this.OnUndoClicked" [ValuesChanged]="this.OnQuantityChanged"
          [(kendoWindowPortal)]="this.portalForWindow">
        </ntw-product-list-item>
      }
    </ng-template>
  </kendo-listview>