<kendo-dialog
  title="{{ 'product.ProductDetailsDialogTitle' | translate }}"
  (close)="close()"
  [width]="dialogSize"
  [height]="dialogSize"
  maxWidth="1460px"
  [maxHeight]="maxDialogHeight"
  >
  @if (loading) {
    <ntw-loading-panel [fullscreen]="false" themeColor="primary"></ntw-loading-panel>
  }
  @if (!loading && pimProductLoaded) {
    <div class="container h-100">
      <div #container class="row align-items-center h-100">
        <div class="col-12 col-lg-6 mh-100">
          <div #mainImageRow
            class="row"
            [ngClass]="{'mh-80': hasEcoLabels, 'mh-100': !hasEcoLabels }"
            >
            <div class="col-12 mh-100">
              <div class="d-flex justify-content-center align-items-center">
                <img #mainImage class="can-img"
                  [style.max-width]="maxMainImageWidth"
                  [style.opacity]="mainImageOpacity"
                  [src]="pimProductImageUrl"
                  (load)="setMainImageSize(mainImage, mainImageRow, container)"
                  (error)="onImageError($event)"
                  title="Can Image"
                  alt="Can Image">
                </div>
              </div>
            </div>
            @if (hasEcoLabels) {
              <div class="row mh-20">
                <div class="col-12 mh-100">
                  <div class="d-flex justify-content-center align-items-center">
                    @for (ecoLabel of pimProductEcoLabels; track ecoLabel) {
                      <img
                        class="ecolabel-img"
                        [style.max-width]="maxEcoLabelWidth"
                        [src]="'assets/images/' + removeSpaces(ecoLabel) + '.png'"
                        [title]="ecoLabel"
                        (error)="onImageError($event, true)"
                        [alt]="ecoLabel">
                    }
                  </div>
                </div>
              </div>
            }
          </div>
          <div class="col-12 col-lg-6 scrollable-content">
            <div class="row row-cols-1 mt-3">
              @for (value of pimFirstRow; track value; let i = $index) {
                <div class="col">
                  <ntw-title-value [titleValue]="value"></ntw-title-value>
                </div>
              }
            </div>
            <div class="row row-cols-1 row-cols-sm-2 row-cols-lg-1 row-cols-xl-2 mt-3">
              @for (value of pimSecondRow; track value; let i = $index) {
                <div class="col">
                  <ntw-title-value [titleValue]="value"></ntw-title-value>
                </div>
              }
            </div>
            <div class="row row-cols-1 row-cols-sm-2 mt-3">
              @for (value of pimThirdRow; track value; let i = $index) {
                <div class="col">
                  <ntw-title-value [titleValue]="value"></ntw-title-value>
                </div>
              }
            </div>
            @if (this.pimProductDataSheets?.length > 0) {
              <div class="row mt-3">
                <div class="col">
                  <div class="row gx-3 mt-1 mb-0">
                    <div class="col-auto">
                      <label class="headerBig">{{'product.DataSheets' | translate}}</label>
                    </div>
                    <div class="col d-flex">
                      <button class="k-button k-button-clear inline"
                        [@rotate]="isDataSheetsCollapsed ? 'collapsed' : 'expanded'"
                        (click)=" isDataSheetsCollapsed = !isDataSheetsCollapsed">
                        <kendo-svg-icon [icon]="icons.chevronDown" size="large"></kendo-svg-icon>
                      </button>
                    </div>
                  </div>
                  <div #scrollTarget class="row" [@collapse]="isDataSheetsCollapsed ? 'closed' : 'open'" (@collapse.start)="onCollapseAnimationStart($event, scrollTarget)">
                    <div class="wrapper">
                      @for (value of pimProductDataSheets; track value; let i = $index) {
                        <div class="col-12 g-2 fs-5">
                          <ntw-download-link [link]="value.staticUrl" [text]="value.name" [subText]="value.typeTranslationCode"></ntw-download-link>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    }
    @if (!loading && !pimProductLoaded) {
      <div class="row row-cols-1 row-cols-sm-2 row-cols-xl-4 mt-3">
        @for (value of firstRow; track value; let i = $index) {
          <div class="col">
            <ntw-title-value [titleValue]="value"></ntw-title-value>
          </div>
        }
      </div>
      <div class="row row-cols-1 row-cols-sm-2 row-cols-xl-4 mt-3">
        @for (value of secondRow; track value; let i = $index) {
          <div class="col">
            <ntw-title-value [titleValue]="value"></ntw-title-value>
          </div>
        }
      </div>
      <div class="row row-cols-1 row-cols-sm-2 row-cols-xl-4 mt-3">
        @for (value of thirdRow; track value; let i = $index) {
          <div class="col">
            <ntw-title-value [titleValue]="value"></ntw-title-value>
          </div>
        }
      </div>
    }
    <kendo-dialog-actions>
      <button class="k-button k-button-md k-rounded-md k-button-solid-base k-button-solid" kendoButton (click)="close()">{{ 'shared.close' | translate}}</button>
    </kendo-dialog-actions>
  </kendo-dialog>