@if (loading) {
  <ntw-loading-panel></ntw-loading-panel>
}
@if (!loading) {
  <div class="row mt-3">
    <div class="col col-lg-10 pb-2">
      <label class="headerBig">{{ 'portalOrder.orderData' | translate }}</label>
    </div>
  </div>
  <div class="row mx-2 my-3">
    @for (value of firstRow; track value; let i = $index) {
      <div class="col-12 col-sm-6 col-lg-3">
        <ntw-title-value [titleValue]="value"></ntw-title-value>
      </div>
    }
  </div>
  <div class="row mx-2 my-3">
    @for (value of secondRow; track value; let i = $index) {
      <div class="col-12 col-sm-6 col-lg-3">
        <ntw-title-value [titleValue]="value"></ntw-title-value>
      </div>
    }
  </div>
  <div class="row mx-2 my-3">
    @for (value of thirdRow; track value; let i = $index) {
      <div class="col-12 col-sm-6 col-lg-3">
        <ntw-title-value [titleValue]="value"></ntw-title-value>
      </div>
    }
  </div>
  <div class="row my-3">
    <div class="col col-lg-10 pb-2">
      <label class="headerBig">{{ 'portalOrder.orderLines' | translate }}</label>
    </div>
  </div>
  <div>
    <ntw-grid-template [currentAccount]="this.currentAccount" [columnSettings]="this.columnSettings"
      idFieldName="LineNum" [selectableSettings]="{enabled: false}" [entities]="this.entities"
      [dataGridView]="this.dataGridView" [summaries]="this.summaries" [loading]="this.loading"
      [dataStateChange]="this.dataStateChange" [kendoGridState]="this.kendoGridState"
      [showCheckBoxColumn]="false" [showMenu]="false" [drillDownEnabled]="false">
    </ntw-grid-template>
  </div>
}