<kendo-dialog title="{{ 'qrcode.generateQrCode' | translate }}" (close)="close()" maxHeight="90vh" [maxWidth]="800"
  minWidth="250px;">
  @if (loading) {
    <ntw-loading-panel [fullscreen]="false" themeColor="primary"></ntw-loading-panel>
  }
  @if (!loading) {
    <div class="container text-center">
      @if (qrCodeData) {
        <div class="qr-code-container mb-4">
          <qrcode [qrdata]="qrCodeData" alt="{{ 'qrcode.generateQrCode' | translate }}" class="img-fluid" [width]="256"
          [errorCorrectionLevel]="'M'"></qrcode>
        </div>
      }
      <div class="text-center countdown" [ngClass]="countdownClass">
        <p><strong>{{ getFormattedCountdown() }}</strong></p>
      </div>
    </div>
  }
  <kendo-dialog-actions>
    <button class="k-button k-button-md k-rounded-md k-button-solid-base k-button-solid" kendoButton (click)="close()">{{ 'shared.close' | translate }}</button>
  </kendo-dialog-actions>
</kendo-dialog>