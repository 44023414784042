<kendo-dialog
  title="{{ 'account.yourWarehouse' | translate }}"
  (close)="close()"
  [minWidth]="400"
  [minHeight]="200"
  [maxWidth]="1320"
  maxHeight="90vh"
  >
  @if (loading) {
    <ntw-loading-panel [fullscreen]="false" themeColor="primary"></ntw-loading-panel>
  }
  @if (!loading) {
    <div class="h-100 d-flex align-items-center">
      <ntw-loading-dropdownlist class="w-100 px-2" [loading]="!warehouses || warehouses.length === 0"
        [defaultItem]="{ warehouseName: 'account.selectWarehouse' | translate }"
        [data]="warehouses" (valueChange)="selectedChanged($event)"
        textField="warehouseName" valueField="bkWarehouseKey">
      </ntw-loading-dropdownlist>
    </div>
  }
  <kendo-dialog-actions>
    <button class="k-button k-button-md k-rounded-md k-button-solid-base k-button-solid" kendoButton (click)="close()">{{ 'shared.ok' | translate}}</button>
  </kendo-dialog-actions>
</kendo-dialog>