<kendo-dropdownbutton id="userOptions" [data]="data" #userOptions>
  <kendo-svg-icon [icon]="this.icons.user"></kendo-svg-icon>
  <ng-template kendoDropDownButtonItemTemplate let-dataItem>
    <div class="m-2">
      @if (isArray(dataItem)) {
        <div (click)="onUserOptionClick($event, dataItem)">
          <kendo-dropdownbutton id="languageUserOptions" #languageUserOptions size="none" fillMode="flat"
            [popupSettings]="{ align: 'center' }" (click)="$event.stopPropagation()" [data]="dataItem.arr">
            <p class="inline">{{dataItem.label | translate }}</p>
            <ng-template kendoDropDownButtonItemTemplate let-dataItem>
              <p class="inline m-2" (click)='onUserOptionClick($event, dataItem)'>{{dataItem.label | translate }}
              </p>
            </ng-template>
          </kendo-dropdownbutton>
        </div>
      } @else {
        <p class="inline" (click)='onUserOptionClick($event, dataItem)'>{{dataItem.label | translate }}</p>
      }
    </div>
  </ng-template>
</kendo-dropdownbutton>