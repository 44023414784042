import { Pipe, PipeTransform } from '@angular/core';
import { NavigationOption } from "./navigation-option";

@Pipe({
    name: 'navigationMenuOptionFilter',
    pure: false
})
export class NavigationMenuOptionFilterPipe implements PipeTransform {
  transform(items: NavigationOption[]): NavigationOption[] {
    if (!items) {
      return items;
    }
    items = items.filter(item => item.isVisible == true);
    return items;
  }
}
