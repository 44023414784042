@if (!areAccountsLoading) {
  <div #contextTreeToggleButton class="d-flex k-cursor-pointer" (click)="expandAccountSelection()">
    @if (selectedAccount) {
      <p class='ps-0 inline select-context'>{{selectedAccount.name}}</p>
    }
    @if (!selectedAccount) {
      <p class='ps-0 inline select-context'>{{ 'navigationTopBar.selectContext' | translate }}</p>
    }
    <button class="me-2 k-button k-button-clear inline select-context-arrow">
      <kendo-svg-icon [icon]="icons.chevrons.chevronDown" [size]="'small'"></kendo-svg-icon>
    </button>
  </div>
}

@if (isAccountSelectionVisible && loading) {
  <div class="k-i-loading select-context-treeview"></div>
}

@if (isAccountSelectionVisible && !loading) {
  <kendo-treeview #treeView [nodes]="contextData" textField="name"
    class="select-context-treeview" kendoTreeViewExpandable kendoTreeViewSelectable kendoTreeViewFlatDataBinding
    idField="accountid" parentIdField="_parentaccountid_value" (selectionChange)="handleSelection($event)"
    [(selectedKeys)]="selectedKeys" [expandedKeys]="expandedKeys">
  </kendo-treeview>
}